var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"e74725b44393add2cc5e8df54c396c0635399008"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (process.env.NODE_ENV !== "development") {
  const isPreview = process.env.NODE_ENV === "preview";
  const sampleRate = isPreview ? 0 : 1;
  Sentry.init({
    dsn:
      SENTRY_DSN ||
      "https://285af2470d1740b2979af975226c9713@o4504933402869760.ingest.sentry.io/4504933727272960",
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,

    // error replay

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    // replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    // replaysOnErrorSampleRate: 1.0,

    integrations: [
      new Sentry.Integrations.Breadcrumbs({ console: false }),
      // new Sentry.Replay({ sampleRate }),
    ],

    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
}
